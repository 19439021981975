import React from "react"

import AllAnimals from "../components/AllAnimals";

const AllAnimalsPage = () => {
  if (typeof window === "undefined") return null

  return <AllAnimals />
}

export default AllAnimalsPage
