import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Button, Tab, Tabs } from "grommet"
import { useApolloClient } from "@apollo/client"
import GET_ANIMALS from "./graphql/getAnimals.graphql"
import FEATURE_ANIMAL from "./graphql/featureAnimal.graphql"
import UNFEATURE_ANIMAL from "./graphql/unfeatureAnimal.graphql"
import Loader from "../UI/Loader"

const Wrapper = styled.div`
  padding: 40px;
  background-color: white;
  position: relative;
`

const LoaderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.2);
  z-index: 10;
  > :first-child {
    position: fixed;
    left: 50%;
    top: 50%;
  }
`

const LoadMore = styled.div`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
`

const Animals = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 1240px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 860px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
  
`

const Animal = styled.div`
  position: relative;
  background-color: #ebebeb;
  padding: 5px;
`

const Artwork = styled.div`
  width: 100%;
  height: 250px;
  margin-bottom: 10px;
  background-image: url(${props => props.src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`
const Name = styled.div`
  text-align: center;
  width: 100%;
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 5px;
`

const Meta = styled.div`
  text-align: center;
  width: 100%;
  color: rgba(0, 0, 0, .8);
  font-size: 12px;
  margin-bottom: 5px;
`

const Actions = styled.div`
  text-align: center;
  width: 100%;
  font-size: 12px;
  margin-bottom: 10px;
`

const PAGE_SIZE = 50

const AllAnimals = () => {
  const [animals, setAnimals] = useState([])
  const [pageNumber, setPageNumber] = useState(0)
  const client = useApolloClient()
  const [canLoadMore, setCanLoadMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const PageTypeForIndex = ['published', 'featured']

  useEffect(() => {
    const initialPageNumber = getPageNumberFromUrl() || 1
    const initialPageType = getPageTypeFromUrl() || 'published'
    setActiveTabIndex(PageTypeForIndex.indexOf(initialPageType))
    loadPage(initialPageType, initialPageNumber)
  }, [])

  const getPageTypeFromUrl = () => {
    return window.location.pathname.indexOf('featured') > -1 ? 'featured': null
  }

  const getPageNumberFromUrl = () => {
    if (window.location.search.indexOf('page=') > -1) {
      return parseInt(window.location.search.split('page=').pop())
    }
  }

  const updateUrlWithPageDetails = (_pageType, _pageNumber) => {
    window.history.replaceState({}, '',`/animals?type=${_pageType}&page=${_pageNumber}`)
  }

  const onLoadMore = () => {
    loadPage(PageTypeForIndex[activeTabIndex], pageNumber + 1)
  }

  const loadPage = async (pageType, pageNumber) => {
    setCanLoadMore(true)
    setLoading(true)
    const variables = {
      limit: PAGE_SIZE,
      skip: (pageNumber - 1) * PAGE_SIZE,
      status: 'published',
    }
    if (pageType === 'featured') variables.featuredV2 = true
    const result = await client.query({
      query: GET_ANIMALS,
      variables,
      fetchPolicy: 'network-only'
    })
    setLoading(false)
    const loadedAnimals = (result && result.data && result.data.animals) || []
    if(loadedAnimals.length > 0) {
      setAnimals(pageNumber === 1 ? loadedAnimals : animals.concat(loadedAnimals))
      setPageNumber(pageNumber)
      updateUrlWithPageDetails(pageType, pageNumber)
    }
    if (loadedAnimals.length < PAGE_SIZE) {
      setCanLoadMore(false)
    }
  }

  const unfeature = (animalId) => {
    client.mutate({
      mutation: UNFEATURE_ANIMAL,
      variables: { animalId }
    })
    let _animals = [...animals]
    let index = animals.findIndex(a => a._id === animalId)
    _animals[index] = {...animals[index], featuredV2: null }
    setAnimals(_animals)
  }

  const feature = (animalId) => {
    client.mutate({
      mutation: FEATURE_ANIMAL,
      variables: { animalId }
    })
    let _animals = [...animals]
    let index = animals.findIndex(a => a._id === animalId)
    _animals[index] = {...animals[index], featuredV2: true }
    setAnimals(_animals)
  }

  const onActive = (nextIndex) => {
    setActiveTabIndex(nextIndex)
    loadPage(PageTypeForIndex[nextIndex], 1)
  }

  const renderAnimal = (animal) => (
    <Animal key={animal._id}>
      <Artwork src={animal.artwork.url} />
      <Name>{animal.name}</Name>
      <Meta>ID: {animal._id} - {(new Date(animal.createdAt)).toLocaleDateString()} - {animal.zookeeper ? animal.zookeeper.username : 'default'}</Meta>
      <Actions>{ animal.featuredV2
        ? <Button size="small" onClick={() => unfeature(animal._id)} primary color="#fa8072" label="Unfeature" />
        : <Button size="small" onClick={() => feature(animal._id)} primary color="#ffd700" label="Feature" />
      }</Actions>
    </Animal>
  )

  const renderLoadMoreButton = () => (
    (loading || !canLoadMore)
      ? null
      : <LoadMore><Button onClick={onLoadMore} size="small" primary label="Load next page"></Button></LoadMore>
  )

  return (
    <Wrapper>
      { loading ? <LoaderContainer><Loader /></LoaderContainer> : null }
      <Tabs margin="small" activeIndex={activeTabIndex} onActive={onActive}>
        <Tab title="published animals">
          <Animals>{animals.map(renderAnimal)}</Animals>
          { renderLoadMoreButton() }
        </Tab>
        <Tab title="featured animals">
          <Animals>{animals.map(renderAnimal)}</Animals>
          { renderLoadMoreButton() }
        </Tab>
      </Tabs>
    </Wrapper>
  )
}

AllAnimals.propTypes = {}

AllAnimals.defaultProps = {}

export default () => (
  <AllAnimals />
)
